import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgxCurrencyDirective, provideEnvironmentNgxCurrency } from "ngx-currency";
import { NgxLoadingModule } from 'ngx-loading';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http); }

import { RepairerSupervisorRoutingModule } from './repairer-supervisor.route';

import { SettingsService } from '../_services/settings.service';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ ],
    imports:
        [
            CommonModule,
            FormsModule,
            NgxCurrencyDirective,
            NgxLoadingModule,
            NgxUiLoaderModule,
            NgScrollbarModule,
            ReactiveFormsModule,
            TooltipModule,
            TranslateModule.forChild({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),

            RepairerSupervisorRoutingModule
        ],
    providers: [
        SettingsService,
        { provide: LOCALE_ID, deps: [SettingsService], useFactory: (settingsService) => settingsService.getLocale() },
        { provide: NG_SCROLLBAR_OPTIONS, useValue: { visibility: 'hover' } },
        provideEnvironmentNgxCurrency({
            align: "right",
            allowNegative: false,
            allowZero: true,
            decimal: ",",
            nullable: true,
            precision: 2,
            prefix: "",
            suffix: "",
            thousands: "."
        }),
    ],
    bootstrap: []
})
export class RepairerSupervisorModule { }
