import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterPageComponent } from '../general/master-page/master-page.component';
import { BuyerDashboardComponent } from '../buyer/dashboard/dashboard.component';
import { BuyerHistoricoComponent } from '../buyer/historico/historico.component';
import { BuyerVisaoGeralComprarComponent } from '../buyer/comprar/comprar.component';
import { BuyerVisaoGeralDetalheComponent } from '../buyer/detalhe/detalhe.component';

const repairerSupervisorRouterConfig: Routes = [
    {
        path: '', component: MasterPageComponent,
        children: [
            { path: 'visao-geral', component: BuyerDashboardComponent },
            { path: 'visao-geral/comprar/:id', component: BuyerVisaoGeralComprarComponent },
            { path: 'visao-geral/pedido/:id', component: BuyerVisaoGeralDetalheComponent },
            { path: 'historico', component: BuyerHistoricoComponent }
        ]
    }
];

@NgModule({
    imports:
        [
            RouterModule.forChild(repairerSupervisorRouterConfig)
        ],
    exports: [RouterModule]
})
export class RepairerSupervisorRoutingModule { }

